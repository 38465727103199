import styled from 'styled-components'

import BlockHomePageDiscoverCard from '../../../components/blocks/BlockHomePageDiscoverCard'

export const HomePageDiscoverCardBlock = styled(BlockHomePageDiscoverCard)`
  .HomePageDiscoverCard {
    height: 100%;
  }
  ul li {
    flex: 1;
  }
`
