import styled from 'styled-components'

export const BlockHomePageDiscoverCard = styled.div``

export const CardList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  ${({ theme }) => theme.breakpoints.up('desktop')} {
    flex-direction: row;
  }
`

export const CardListItem = styled.li``
