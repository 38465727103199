import { FC } from 'react'
import cx from 'classnames'
import { ImageProps } from 'next/image'
import { useTheme } from 'styled-components'

import { LinkProps } from '../Link'
import { Icons } from '../Icon'

import * as SC from './styled'

export type HomePageDiscoverCardProps = {
  className?: string
  image?: ImageProps
  title: string
  description: string
  link: LinkProps
}

const HomePageDiscoverCard: FC<HomePageDiscoverCardProps> = ({
  className,
  image,
  title,
  description,
  link,
}) => {
  const theme = useTheme()

  return (
    <SC.HomePageDiscoverCard className={cx('HomePageDiscoverCard', className)}>
      <SC.LeftContent>{image && <SC.Img {...image} />}</SC.LeftContent>
      <SC.RightContent>
        <SC.Title>{title}</SC.Title>
        <SC.Separator />
        <SC.Description>{description}</SC.Description>
        <SC.Lk {...link}>
          {link.label}
          <SC.ArrowSvg
            icon={Icons.arrowRight}
            width={16}
            height={13}
            color={theme.colors.pureWhite}
          />
        </SC.Lk>
      </SC.RightContent>
    </SC.HomePageDiscoverCard>
  )
}

export default HomePageDiscoverCard
