import styled from 'styled-components'
import Image from 'next/image'

import Link from '../Link'
import Icon from '../Icon'

export const HomePageDiscoverCard = styled.div`
  background-color: ${({ theme }) => theme.colors.bluePrimary};
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  border-radius: 2rem;
  padding: 3rem;

  ${({ theme }) => theme.breakpoints.up('tablet')} {
    flex-direction: row;
    padding: 4rem;
  }
`

export const LeftContent = styled.div`
  margin: auto;
  flex: 1;
`

export const Img = styled(Image)`
  border-radius: 2rem;
  width: 100%;
  height: 290px;
  object-fit: cover;
`

export const RightContent = styled.div`
  color: ${({ theme }) => theme.colors.pureWhite};
  flex: 1;

  ${({ theme }) => theme.breakpoints.up('tablet')} {
    display: flex;
    flex-direction: column;
  }
`

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.firaSans2XlBold};
  margin: 0;

  ${({ theme }) => theme.breakpoints.up('tablet')} {
    min-height: 7rem;
  }
`

export const Separator = styled.div`
  height: 0.2rem;
  border-radius: 2rem;
  background-color: ${({ theme }) => theme.colors.blueBack};
  margin-top: 2rem;
`

export const Description = styled.p`
  ${({ theme }) => theme.textStyles.montserratSm};
  margin-top: 4rem;
`

export const Lk = styled(Link)`
  ${({ theme }) => theme.textStyles.montserratLgBold};
  color: ${({ theme }) => theme.colors.pureWhite};
  display: flex;
  align-items: center;
  width: max-content;
  margin-top: 4rem;

  ${({ theme }) => theme.breakpoints.up('tablet')} {
    margin-top: auto;
  }

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }
`

export const ArrowSvg = styled(Icon)`
  margin-left: 2rem;
  transform: translateX(0);
  transition: transform 320ms ease-in-out;
`
