import { FC, useMemo } from 'react'

import { BlockProps } from '../props'
import SectionWrapper from '../../../components/SectionWrapper'
import { BlockHomePageDiscoverCardProps } from '../../../components/blocks/BlockHomePageDiscoverCard'
import { ImageTransformer } from '../../Transformers/ImageTransformer'

import { BlocksSideBySideProps } from './props'
import * as SC from './styled'

const BlocksSideBySide: FC<BlockProps<BlocksSideBySideProps>> = ({ data }) => {
  const props: BlockHomePageDiscoverCardProps = useMemo(
    () => ({
      items: [
        {
          image: ImageTransformer(data?.imageLeft?.data),
          title: data?.titleLeft,
          description: data?.textLeft,
          link: {
            label: data?.linkLeft?.text,
            href: data?.linkLeft?.href,
            target: data?.linkLeft?.target,
          },
        },
        {
          image: ImageTransformer(data?.imageRight?.data),
          title: data?.titleRight,
          description: data?.textRight,
          link: {
            label: data?.linkRight?.text,
            href: data?.linkRight?.href,
            target: data?.linkRight?.target,
          },
        },
      ],
    }),
    [data]
  )
  return (
    <SectionWrapper spacing="medium" withBackgroundColor>
      <SC.HomePageDiscoverCardBlock {...props} />
    </SectionWrapper>
  )
}
export default BlocksSideBySide
