import React, { FC } from 'react'
import cx from 'classnames'

import HomePageDiscoverCard, {
  HomePageDiscoverCardProps,
} from '../../HomePageDiscoverCard'

import * as SC from './styled'

export type BlockHomePageDiscoverCardProps = {
  className?: string
  items: Array<HomePageDiscoverCardProps>
}

const BlockHomePageDiscoverCard: FC<BlockHomePageDiscoverCardProps> = ({
  className,
  items,
}) => {
  return (
    <SC.BlockHomePageDiscoverCard
      className={cx('BlockHomePageDiscoverCard', className)}
    >
      <SC.CardList>
        {items.map((item, index) => (
          <SC.CardListItem key={index}>
            <HomePageDiscoverCard {...item} />
          </SC.CardListItem>
        ))}
      </SC.CardList>
    </SC.BlockHomePageDiscoverCard>
  )
}

export default BlockHomePageDiscoverCard
